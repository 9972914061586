import React from "react";
import { Download_Page } from "../../HeroSection";
import { Download } from "./DownloadData";

function DownloadBeatBoi() {
  return (
    <>
      <Download_Page {...Download} />
    </>
  );
}

export default DownloadBeatBoi;
