export const Download = {
  lightBg: false,
  lightText: false,
  headline: "Download BeatBoi Now!",
  buttonLabelApp: "App Store",
  buttonLabelPlay: "Google Play Store",
  imgApp: "images/App.png",
  altApp: "",
  imgPlay: "images/Google.png",
  altPlay: "",
};
